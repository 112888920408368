import { useSession } from "next-auth/react";
import StartLayout from "../../layouts/start";
import AppLayout from "../../layouts/app";

export default Layout;

interface Props {
  children: React.ReactNode;
}
function Layout({ children }: Props) {
  const { data } = useSession();
  return (!data?.user || data?.error) ? (
    <StartLayout>{children}</StartLayout>
  ) : (
    <AppLayout>{children}</AppLayout>
  );
}
