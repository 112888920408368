import { Button } from "@repos/digikoo-design-system-react/button";
import { signIn, useSession } from "next-auth/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import HelperPage from "../../components/sections/helper-page";
export { Page };

function Page() {
  const { data } = useSession();
  const { t } = useTranslation();
  const renderPage = useMemo(
    () => (
      <div className="max-w-sm text-center">
        <h2 className="headline-2 text-primary-600">
          {t("index.headline", { ns: "start" })}
        </h2>
        <Button
          className="mt-8 bg-primary-600"
          onClick={() => {
            signIn("keycloak");
          }}
        >
          {t("index.cta", { ns: "start" })}
        </Button>
      </div>
    ),
    [t]
  );
  return !data?.user || data?.error ? renderPage : <HelperPage />;
}
