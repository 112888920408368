import parse from "html-react-parser";
import step1Image from "../../assets/images/welcome-step1.png";
import step2Image from "../../assets/images/welcome-step2.png";
import step3Image from "../../assets/images/welcome-step3.png";
import Checkbox from "@repos/digikoo-design-system-react/checkbox";
import { Button } from "@repos/digikoo-design-system-react/button";

import { useTranslation } from "react-i18next";
import { useState } from "react";

const HelperPage = () => {
  //hooks
  const { t } = useTranslation();
  const [helperConsent, setHelperConsent] = useState<boolean | undefined>(undefined);

  //steps for helper
  const steps = [
    {
      id: 1,
      img: step1Image,
    },
    {
      id: 2,
      img: step2Image,
    },
    {
      id: 3,
      img: step3Image,
    },
  ];

  return (
      <div className="col-start-[content] col-end-[content] mt-5">
        {/* Head */}
        <div className="text-center">
          <h1 className="headline-1">
            {parse(t("welcome.headline", { ns: "app" }))}
          </h1>

          <p className="mt-4 text-20 leading-normal">
            {t("welcome.description", { ns: "app" })}
          </p>
        </div>

        <div className="mt-10">
          {/* Steps */}
          <div className="flex gap-x-10 ">
            {steps.map(({ id, img }) => {
              const title = t("step", { ns: "common" }) + " " + id;

              return (
                <div
                  key={id}
                  className="shadow-2x1 bg-white flex grow flex-col pb-5 pl-5 rounded-2x1 w-[33%]"
                >
                  <img className="mt-5 h-48" src={img} alt="" />
                  <div className="Label"> {title} </div>
                  <div className="headline-3">
                    {" "}
                    {t(`welcome.steps.${id}.title`, { ns: "app" })}{" "}
                  </div>
                  <div className="copy-3">
                    {" "}
                    {t(`welcome.steps.${id}.description`, { ns: "app" })}{" "}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="max-w-md pt-10 mx-auto text-center">
            <Button
              id="btnStart"
              onClick={() => {
                  if (helperConsent) {
                  document.cookie =
                    "digikoo-configurator-helper=true; path=/; max-age=86400";
                } else {
                  //set max-age=1 to make cookie disappear after refresh
                  document.cookie =
                    "digikoo-configurator-helper=true; path=/; max-age=1";
                }

                document.location.href = "/";
              }}
            >
              {t("welcome.start", { ns: "app" })}
            </Button>
          </div>
          <div className="max-w-md pt-5 mx-auto text-center">
            <div className="inline-flex align-top">
              <Checkbox
                id="chkNoAsk"
                checked={helperConsent}
                onCheckedChange={(val: boolean) => {
                  setHelperConsent(val);
                }}
              ></Checkbox>
              <label className="Label px-2" htmlFor="chkNoAsk">
                {t("welcome.check", { ns: "app" })}
              </label>
            </div>
          </div>
        </div>
      </div>
  );
};

export default HelperPage;
